import React from 'react';
import Layout from '../layouts/Layout';
import SignInForm from '../components/SignInForm';
import MetaData from '../components/MetaData';
import { shape, string } from 'prop-types';
import qs from 'query-string';

SignIn.propTypes = {
	location: shape({
		search: string.isRequired
	}).isRequired
};

export default function SignIn({ location: { search } }) {
	const { returnUrl } = qs.parse(search || '');

	return (
		<Layout>
			<MetaData title="Sign in" />
			<SignInForm returnUrl={returnUrl} />
		</Layout>
	);
}
