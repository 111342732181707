import React, { useEffect } from 'react';
import useStyles from '../hooks/useStyles';
import useBatchState from '../hooks/useBatchState';
import FormHeading from './FormHeading';
import Button from './button/Button';
import Checkbox from './form/Checkbox';
import FormError from './form/FormError';
import PasswordField from './form/PasswordField';
import TextField from './form/TextField';
import { bool, string, func } from 'prop-types';
import { getQaKey as qa } from '../util/qa';
import { Link } from 'gatsby';
import useGlobal from '../hooks/useGlobal';
import { PATH, LOGIN_PATH } from '../consts';
import { useSignIn } from '../util/api';

SignInForm.propTypes = {
	emailDisabled: bool,
	email: string,
	onSuccess: func,
	returnUrl: string,
	showRememberOption: bool
};

SignInForm.defaultProps = {
	onSuccess: () => window.location.replace(LOGIN_PATH)
};

const MESSAGE = {
	detailsIncorrect: 'Your details are incorrect. Please try again.',
	emailLabel: 'Your email',
	forgotPasswordButton: 'Forgot your password?',
	invalidInput: 'Please enter a valid email and password',
	pageTitle: 'Sign in to your account',
	passwordLabel: 'Password',
	signInButton: 'Sign in'
};

export default function SignInForm({ emailDisabled, returnUrl, showRememberOption, onSuccess, email: emailProps }) {
	const classes = useStyles(styles);
	const { email, setGlobal } = useGlobal();
	const [{ password, remember, error }, setState] = useBatchState({
		password: '',
		remember: true
	});
	const [{ loading, error: serviceError, status, data: { redirectUrl } = {} }, signIn] = useSignIn();

	useEffect(() => {
		if (emailProps) setGlobal({ email: emailProps });
	}, []);

	useEffect(() => {
		if (status === 200) {
			if (!returnUrl) {
				onSuccess();
			} else if (redirectUrl) {
				window.location = redirectUrl;
			}
		}
	}, [status, redirectUrl, onSuccess, returnUrl]);

	const onCheckRemember = ({ target: { checked } }) => {
		setState({ remember: checked });
	};

	const onChangeEmail = ({ target: { value: email } }) => {
		setGlobal({ email });
	};

	const onNavigate = () => {
		setGlobal({ email });
	};

	const onChangePassword = ({ target: { value } }) => {
		setState({ password: value });
	};

	const onSubmit = e => {
		e.preventDefault();
		if (e.target.checkValidity()) {
			setState({
				error: undefined
			});
			signIn(email, password, returnUrl);
		} else {
			setState({ error: MESSAGE.invalidInput });
		}
	};

	const getErrorFromStatus = () => {
		if (status === 401) {
			return MESSAGE.detailsIncorrect;
		}
	};

	return (
		<>
			<FormHeading css={classes.heading} heading={MESSAGE.pageTitle} />
			<div css={classes.signIn}>
				<form noValidate onSubmit={onSubmit} css={classes.form}>
					<FormError error={error || serviceError || getErrorFromStatus()} />
					<div css={classes.inputs}>
						<TextField
							name="email"
							onChange={onChangeEmail}
							required
							type="email"
							placeholder="email@yourcompany.com"
							autoComplete="email"
							label={MESSAGE.emailLabel}
							value={email}
							disabled={emailDisabled}
							autoFocus={!emailDisabled}
							{...qa('input:email')}
						/>
						<PasswordField
							name="password"
							autoComplete="current-password"
							css={classes.passwordField}
							required
							label={MESSAGE.passwordLabel}
							value={password}
							autoFocus={!!emailDisabled}
							onChange={onChangePassword}
							placeholder="Password (at least 8 characters)"
							{...qa('input:password')}
						/>
						{showRememberOption && (
							<Checkbox name="remember" checked={remember} onChange={onCheckRemember} {...qa('input:remember')}>
								Stay signed in
							</Checkbox>
						)}
					</div>
					<Button type="submit" className="full-width" loading={loading} qaKey="btn:sign-in">
						{MESSAGE.signInButton}
					</Button>
				</form>
				<div css={classes.otherActions}>
					<Link to={PATH.forgotPassword} {...qa('link:forgot-pw')} onClick={onNavigate}>
						{MESSAGE.forgotPasswordButton}
					</Link>
				</div>
			</div>
		</>
	);
}

function styles({ css }) {
	return {
		form: css`
			width: 360px;
			margin-bottom: 24px;
		`,
		inputs: css`
			position: relative;
			& > :not(:last-child) {
				margin-bottom: 16px;
			}
		`,
		passwordField: css`
			height: 96px;
		`,
		heading: css`
			margin-bottom: 24px;
		`,
		signIn: css`
			width: 100%;
		`,
		otherActions: css`
			display: flex;
			justify-content: space-around;
			width: 100%;
			max-width: 360px;
		`
	};
}
